import { ThemeProvider } from "@mui/material"
import './App.css';
import MainPage from './MainPage';
import theme from './theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      < MainPage />
    </ThemeProvider>
  );
}

export default App;
