import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PaymentIcon from '@mui/icons-material/Payment';
import logo from "./images/logo.png"
import banner from "./images/banner.png"

const skills = [
  '4 years of experience as clinical dietician from a multiple speciality hospital',
  'Master of Science (MSc) Food and Nutrition',
  'Post Graduate Diploma in Dietetics',
  'Expertise in all kinds of diet plans including clinical, sports and general diet'
];
const skillsList = skills.map((skill)=>{
  return <li>
      <Typography variant="body1" >
        {skill}
      </Typography>
    </li>;
});

const services = [
'Balanced Diet',
'Therapeutic Diet', 
'Weight Loss or Gain Diet',
'Pregnant & Lactating Women Diet',
'Gym & Sports Diet',
'Infants and Children Diet',
'PCOS & PCOD Diet',
'Endocrine Diet',
'Critical Illness Diet for Cancer, Diabetes, Asthma, Cardiovascular diseases, Gastrointestinal diseases, Liver Pancreas and Kidney diseases'
];

const servicesList = services.map((service)=>{
  return <li>
      <Typography variant="body1" >
        {service}
      </Typography>
    </li>;
});

const MainPage = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#343b5c', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Toolbar variant="dense">
          <IconButton edge="end" color="inherit" sx={{ mr: 2 }}>
            <img src={logo} alt="Shalini Tyagi" width="200px" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ flexGrow: 1, margin: 3 }}>
        <img width="100%"
          src={banner} alt="Shalini Tyagi" 
        />
        <Typography variant="h6" sx={{ color: '#343b5c' }}>
          About Me
        </Typography>
        <Typography variant="body1" >
          Hi! I am Dietician Shalini Tyagi. I am an expert food and nutrition consultant. I have:
        </Typography>
        <ul>
            {skillsList}
        </ul>

        <Typography variant="h6" sx={{ color: '#343b5c' }}>
          Services
        </Typography>
        <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={4}>
          <ul>
            {servicesList}
          </ul>

          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ color: '#343b5c' }}>
          Contact
        </Typography>
        <Chip icon={<CallIcon />} label="+91 73107 64644 (WhatsApp or Call)" variant="outlined"/>
        <Chip icon={<EmailIcon />} label="tyagi.shalini1710@gmail.com" variant="outlined"/>

        <Typography variant="h6" sx={{ color: '#343b5c' }}>
          Payment
        </Typography>
        <Chip icon={<PaymentIcon />} label="+91 73107 64644 (GooglePay or PhonePay)" variant="outlined"/>
      </Box>
    </Box>
  );
};
export default MainPage;
