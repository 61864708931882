import { createTheme } from "@mui/material"
import "@fontsource/alef"

const theme = createTheme({
    typography: {
      fontFamily: [
        "Alef",
        "sans-serif"
      ].join(",")
    }
  });

  export default theme;